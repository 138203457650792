@import './index-tablet.module.scss';
@import './index-mobile.module.scss';

.image5Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}
.groupChild {
  background-color: var(--color-gray-100);
  width: 1440px;
  height: 108px;
}
.vectorIcon {
  position: relative;
  width: 49px;
  height: 32px;
}
.rwa20 {
  position: relative;
  font-weight: 600;
}
.vectorParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-sm);
}
.telegramIcon {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.frameWrapper,
.telegramParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.lightLine1 {
  background: linear-gradient(90deg, rgba(103, 103, 103, 0) 0%, #676767 50%, rgba(103, 103, 103, 0) 100%);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
}
.lightLine2 {
  background: linear-gradient(90deg, rgba(103, 103, 103, 0) 0%, #676767 50%, rgba(103, 103, 103, 0) 100%);
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
}
.telegramParent {
  align-items: flex-start;
  gap: var(--gap-lg);
}
.frameWrapper {
  align-items: center;
  margin-left: auto;
}
.fairLaunch {
  position: relative;
  font-weight: 500;
  cursor: pointer;
}
.fairLaunchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-xl);
  color: var(--color-gray-200);
}
.groupItem {
  position: absolute;
  top: 107px;
  left: 112px;
  background: linear-gradient(90deg, rgba(103, 103, 103, 0), #676767 50%, rgba(103, 103, 103, 0));
  width: 1216px;
  height: 1px;
}
.header {
  width: 1200px;
  height: 108px;
  font-size: var(--font-size-9xl);
  color: var(--color-white);
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}

.main {
  width: 1200px;
  margin: 0 auto;
}
.image18Icon {
  width: 1118px;
  height: 505px;
  object-fit: cover;
  margin: 102px auto;
}
.arrowOutwardFill0Wght400GrIcon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.mintNowParent {
  margin: 55px auto;
  border-radius: var(--br-14xl);
  background: linear-gradient(126.76deg, #d1dcee, rgba(208, 218, 234, 0.23));
  box-sizing: border-box;
  width: 230px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-21xl);
  gap: var(--gap-7xs);
  color: var(--color-gray-100);
}
.expandingEcosystem {
  flex: 1;
  position: relative;
  background: linear-gradient(91deg, #fff, rgba(255, 255, 255, 0.6));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
}
.frameChild {
  position: relative;
  // width: 60px;
  // height: 60px;
}
.groupWrapper {
  border-radius: var(--br-5xl);
  background: linear-gradient(180deg, rgba(37, 40, 44, 0.37), rgba(63, 66, 71, 0.19));
  backdrop-filter: blur(30px);
  border: 1px solid var(--color-gray-600);
  box-sizing: border-box;
  width: 180px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.expandingEcosystemParent,
.frameParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.frameParent {
  align-items: flex-start;
  gap: var(--gap-21xl);
}
.expandingEcosystemParent {
  width: 1216px;
  align-items: center;
  margin: 0 auto;
  margin-top: -300px;
  font-size: var(--font-size-21xl);
}
.brc20Extension {
  font-weight: 600;
}
.inscribeTheWorld,
.theBrc20ExtensionContainer {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.theBrc20ExtensionContainer {
  text-align: center;
  font-family: Outfit;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 22px;
  background: radial-gradient(
    3720.87% 30.16% at 30.16% 50%,
    rgba(255, 255, 255, 0.8) 45.31%,
    rgba(255, 255, 255, 0.5) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}
.inscribeTheWorld {
  text-align: center;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
  font-family: Outfit;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 80px;
  background: linear-gradient(91deg, #fff 0.35%, rgba(255, 255, 255, 0.6) 102.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 102px;
}
.groupInner {
  background-color: var(--color-gray-100);
  width: 100%;
}
.copyrightRwa20Wrapper,
.frameContainer,
.vectorGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.vectorGroup {
  gap: var(--gap-sm);
}

.frameContainer {
  margin-left: auto;
}
.copyrightRwa20Wrapper {
  bottom: 42px;
  left: 354.5px;
  align-items: flex-start;
  font-size: var(--font-size-xl);
  color: var(--color-gray-200);
}
.rectangleDiv {
  position: absolute;
  bottom: 107px;
  left: 112px;
  background: linear-gradient(90deg, rgba(103, 103, 103, 0), #676767 50%, rgba(103, 103, 103, 0));
  width: 1216px;
  height: 1px;
}
.footer {
  width: 1200px;
  margin: 0 auto;
  font-size: var(--font-size-9xl);
  color: var(--color-white);
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 36px;
  padding-bottom: 36px;
  position: relative;
}
.inscriptionReleased {
  align-self: stretch;
  position: relative;
  background: linear-gradient(91deg, #fff, rgba(255, 255, 255, 0.6));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
}
.theInauguralConcept {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 26px;
}
.inscriptionReleasedParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.mintProgressParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.groupChild1,
.groupChild2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-dimgray);
  box-sizing: border-box;
  height: 10px;
}
.groupChild1 {
  background: linear-gradient(-61.38deg, #566070, rgba(86, 96, 112, 0.15));
  width: 480px;
  opacity: 0.2;
}
.groupChild2 {
  background: linear-gradient(126.76deg, #566070, rgba(86, 96, 112, 0.15));
  width: 212px;
}
.rectangleContainer {
  position: relative;
  width: 480px;
  height: 10px;
}
.frameParent1,
.frameParent2 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent2 {
  display: flex;
  justify-content: center;
  gap: 8px;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
}
.frameParent1 {
  gap: var(--gap-21xl);
}
.frameDiv,
.frameParent1,
.mintNowGroup {
  display: flex;
  justify-content: center;
}
.mintNowGroup {
  border-radius: var(--br-14xl);
  border-radius: 33px;
  border: 1px solid #9297a1;
  background: linear-gradient(127deg, #566070 21.73%, rgba(86, 96, 112, 0) 100.1%);
  box-sizing: border-box;
  width: 230px;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-base) var(--padding-21xl);
  gap: var(--gap-7xs);
  font-size: var(--font-size-xl);
}
.frameDiv {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-61xl);
  z-index: 0;
}
.image21Icon {
  width: 420px;
  height: 404px;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
}
.frameGroup {
  border-radius: var(--br-5xl);
  background: linear-gradient(98.52deg, #1b2029, #060607 27.5%, #060607 72%, #1c2129);
  backdrop-filter: blur(30px);
  box-sizing: border-box;
  width: 1216px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 110px;
  gap: var(--gap-61xl);
  font-size: var(--font-size-21xl);
  margin-top: 200px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0);
  background: linear-gradient(99deg, #1b2029 2.18%, #060607 26.67%, #060607 66.3%, #1c2129 91.24%);
  backdrop-filter: blur(15px);
}

.compatibleStandardsWrap {
  position: relative;
  margin: 200px auto;
}
.compatibleStandards {
  font-size: var(--font-size-41xl);
  background: linear-gradient(91deg, #fff, rgba(255, 255, 255, 0.6));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);

  text-align: center;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  background: linear-gradient(91deg, #fff 0.35%, rgba(255, 255, 255, 0.6) 102.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.image12Icon {
  position: absolute;
  top: 346px;
  left: 1110px;
  width: 260px;
  height: 174px;
  object-fit: cover;
}
.image14Icon,
.image7Icon,
.image9Icon {
  position: absolute;
  top: 558px;
  left: 1024px;
  width: 272px;
  height: 221px;
  object-fit: cover;
}
.image7Icon,
.image9Icon {
  top: 195px;
  left: 0;
  width: 159px;
  height: 106px;
}
.image7Icon {
  top: 73px;
  left: 43px;
  width: 199px;
  height: 163px;
}
.image10Icon,
.image11Icon,
.image8Icon {
  position: absolute;
  top: 22px;
  left: 462px;
  width: 175px;
  height: 160px;
  object-fit: cover;
}
.image10Icon,
.image11Icon {
  top: 0;
  left: 1006px;
  width: 205px;
  height: 151px;
}
.image11Icon {
  top: 254px;
  left: 1026px;
  width: 153px;
  height: 153px;
}
.image13Icon,
.image15Icon,
.image16Icon {
  position: absolute;
  top: 531px;
  left: 1000px;
  width: 109px;
  height: 166px;
  object-fit: cover;
}
.image15Icon,
.image16Icon {
  top: 503px;
  left: 0;
  width: 270px;
  height: 185px;
}
.image16Icon {
  top: 649px;
  left: 424px;
  width: 280px;
  height: 213px;
  object-fit: contain;
}
.rwa20IsAnWrap {
  position: relative;
  height: 870px;
  margin: 150px auto;
  padding: 230px 50px;
}
.image12Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.aboutRwa20,
.rwa20IsAn {
  text-align: center;
}
.rwa20IsAn {
  font-size: var(--font-size-base);
  line-height: 26px;
  width: 824px;
  margin: 30px auto;
}
.aboutRwa20 {
  top: 2284px;
  left: calc(50% - 202px);
  font-size: var(--font-size-41xl);
  background: linear-gradient(91deg, #fff, rgba(255, 255, 255, 0.6));
}
.aboutRwa20,
.leanMore,
.supportedUseCases {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
}
.supportedUseCases {
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(91deg, #fff 0.35%, rgba(255, 255, 255, 0.6) 102.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.supportedUseCasesWrapHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leanMore {
  position: relative;
  font-weight: 500;
  text-shadow: 4px 4px 20px rgba(255, 255, 255, 0.2);
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(91deg, #fff 0.35%, rgba(255, 255, 255, 0.72) 102.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.leanMoreParent {
  border-radius: var(--br-14xl);
  background: linear-gradient(126.76deg, #566070, rgba(86, 96, 112, 0));
  border: 1px solid var(--color-lightslategray);
  box-sizing: border-box;
  width: 230px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-21xl);
  gap: var(--gap-7xs);
  margin: 50px auto;
}

.leanMoreGroup {
  border-radius: var(--br-14xl);
  background: linear-gradient(126.76deg, #566070, rgba(86, 96, 112, 0));
  border: 1px solid var(--color-lightslategray);
  box-sizing: border-box;
  width: 230px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-21xl);
  gap: var(--gap-7xs);
  cursor: pointer;
}
.privateEquityAndVentureCapParent,
.realEstateTransactionsParent,
.tokenizationOfArtAndPrecioParent {
  align-self: stretch;
  border-radius: var(--br-5xl);
  background: linear-gradient(180deg, rgba(37, 40, 44, 0.37), rgba(63, 66, 71, 0.19));
  backdrop-filter: blur(30px);
  border: 1px solid var(--color-gray-600);
  box-sizing: border-box;
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.frameParent3 {
  width: 100%;
  display: flex;
  gap: 20px;
  font-size: var(--font-size-13xl);
  margin-top: 100px;
}
.vectorIcon2 {
  position: relative;
  width: 56px;
  height: 28px;
}
.frameParent5,
.vectorWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.vectorWrapper {
  border-radius: var(--br-39xl);
  background: radial-gradient(50% 50%at 50% 50%, #54647a 12.93%, rgba(71, 82, 97, 0) 71.75%);
  border: 2px solid var(--color-gray-500);
  width: 100px;
  height: 100px;
  flex-direction: column;
}
.frameParent5 {
  border-radius: var(--br-5xl);
  background: linear-gradient(180deg, rgba(37, 40, 44, 0.37), rgba(63, 66, 71, 0.19));
  backdrop-filter: blur(30px);
  border: 1px solid var(--color-gray-600);
  width: 360px;
  height: 170px;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-17xl) 0;
  gap: var(--gap-17xl);
}
.groupIcon {
  position: relative;
  width: 36.1px;
  height: 36px;
}
.groupIcon1,
.vectorIcon3 {
  position: relative;
  width: 40px;
  height: 46px;
}
.groupIcon1 {
  width: 28.8px;
}
.frameParent4 {
  width: 1216px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;
  font-size: var(--font-size-7xl);
  margin: 80px auto;
}

.rwa20WebsiteDefault {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-gray-400);
  font-family: var(--font-outfit);
}

/* 小于1024px tablet */
@media screen and (max-width: 1024px) {
  @include tablet();
}
/* 小于480px mobile */
@media screen and (max-width: 480px) {
  @include mobile();
}
